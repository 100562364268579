export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function delayUntil(done: () => boolean, intervalMs: number): Promise<void> {
  while (!done()) {
    await delay(intervalMs);
  }
}

export type AbortableFunction<T> = (abortSignal: AbortSignal) => Promise<T>;

function createWaitCancelled(shouldCancel: () => boolean, delay: () => Promise<void>): AbortableFunction<{ kind: "CANCELLED" }> {
  return async (signal: AbortSignal) => {
    while (true) {
      if (shouldCancel() || signal.aborted) {
        return { kind: "CANCELLED" };
      }
      await delay();
    }
  };
}

export async function runAbortableFunctions<MaybeResult extends { kind: string }>(abortableFunctions: AbortableFunction<MaybeResult>[], shouldCancel: () => boolean, delay: () => Promise<void>): Promise<{ kind: "CANCELLED" } | MaybeResult> {
  const waitCancelled = createWaitCancelled(shouldCancel, delay);

  const controller = new AbortController();

  const result = await Promise.race([...abortableFunctions, waitCancelled].map((fn) => fn(controller.signal)));

  controller.abort();
  return result;
}
