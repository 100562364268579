import type { AxiosError } from "axios";

export class AppError extends Error {
  public link?: string;
  public originalError: AxiosError | undefined;

  constructor(m: string, link?: string, originalError?: AxiosError) {
    super(m);
    this.link = link;
    this.originalError = originalError;
    Object.setPrototypeOf(this, AppError.prototype);
  }
}

export class NotAuthorizedException extends AppError {
  needsRefresh: boolean;

  constructor(needsRefresh: boolean) {
    super("not authorized login required");
    this.needsRefresh = needsRefresh;
    Object.setPrototypeOf(this, NotAuthorizedException.prototype);
  }
}

export class AccessForbiddenException extends AppError {
  constructor(error: AxiosError) {
    super("Access forbidden", undefined, error);
    Object.setPrototypeOf(this, AccessForbiddenException.prototype);
  }
}

export class NotFoundException extends AppError {
  constructor() {
    super("Not found");
    Object.setPrototypeOf(this, NotFoundException.prototype);
  }
}

// Pseudo error to flag a Promise.reject() when an operation is cancelled by the user (e.g. from a confirm modal)
export class CancelledException extends AppError {
  constructor() {
    super("Cancelled");
    Object.setPrototypeOf(this, CancelledException.prototype);
  }
}
