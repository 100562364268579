export type Dict<T> = Record<string, T>;

export function toDict<Value>(arr: [string, Value][]): Dict<Value> {
  const result: { [key: string]: Value } = {};
  arr.forEach(([key, value]) => {
    result[key] = value;
  });
  return result;
}

export function dictToList<Value>(dict: Dict<Value>): [string, Value][] {
  return Object.entries(dict);
}

export function filterDict<Value>(dict: Dict<Value>, fn: (key: string, value: Value) => boolean): Dict<Value> {
  return toDict(
    dictToList(dict).filter(([key, value]) => {
      return fn(key, value);
    }),
  );
}

export function mapValues<Value, R>(dict: Dict<Value>, callback: (value: Value) => R): Dict<R> {
  return toDict(
    dictToList(dict).map(([key, value]): [string, R] => {
      return [key, callback(value)];
    }),
  );
}
