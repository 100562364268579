export function assert(condition: boolean, msg: unknown = null): asserts condition {
  if (!condition) {
    throw Error("assertion failed: " + msg);
  }
}

export function assertIsDefined<T>(value: T, msg?: string): asserts value is NonNullable<T> {
  assert(value !== undefined && value !== null, msg);
}

export function ensureNonNull<T>(v: T, msg?: string): NonNullable<T> {
  assertIsDefined(v, msg);
  return v as NonNullable<T>;
}

export function assertIsString(value: unknown, message?: string): asserts value is string {
  assert(value !== undefined && typeof value === "string", message);
}

export function assertIsNotEmptyString(value: unknown, message?: string): asserts value is string {
  assertIsString(value, message);
  assert(value.length > 0, message);
}

export function assertIsNumber(value: unknown, message?: string): asserts value is number {
  assert(value !== undefined && typeof value === "number", message);
}

export function assertIsBigint(value: unknown, message?: string): asserts value is bigint {
  assert(value !== undefined && typeof value === "bigint", message);
}

export function assertIsBoolean(value: unknown, message?: string): asserts value is boolean {
  assert(typeof value === "boolean", message);
}

export function assertIsArray<T>(value: unknown, message?: string): asserts value is T[] {
  assert(value !== undefined && Array.isArray(value), message);
}

export function assertIsTypedArrayOf<T>(value: unknown[], validator: (value: unknown) => boolean): asserts value is T[] {
  value.forEach((entry) => assert(validator(entry)));
}

export function assertIsObject(value: unknown, message?: string): asserts value is object {
  assert(typeof value === "object" && value !== null, message);
}

export function hasProp<K extends string>(data: object, prop: K): data is Record<K, unknown> {
  return prop in data;
}
