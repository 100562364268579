/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type FrontendProject, parseFrontendProject, renderFrontendProject } from '../model/frontendProject';
// @ts-ignore
import { type Translations, parseTranslations, renderTranslations } from '../model/translations';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* Get translations of a certain language for one of the frontend projects.
* @param frontendProject 
* @param isoCode 
*/
export async function getFrontendTranslations(frontendProject: FrontendProject, isoCode: 'en' | 'de' | 'es' | 'ru' | 'zh' | 'pt' | 'fr'): Promise<Translations> {
        return getHttpClient().request<Translations>({
            url: '/gaapi/txt/txids/{frontendProject}/{isoCode}'
                .replace('{' + 'frontendProject' + '}', encodeURIComponent(String(frontendProject)))
                .replace('{' + 'isoCode' + '}', encodeURIComponent(String(isoCode))),
            method: 'GET'
        })
        .then(response => {
               return parseTranslations(response.data);
        });
}

