import { Language } from "@utils/type/type";
import { createI18n, type LocaleMessages } from "vue-i18n";
import { delay } from "@utils/asyncUtils";
import { assert } from "@utils/assertion";
import { getFrontendTranslations } from "@generated/api/txtRestControllerApi";

type TMSMessage = { lang: Language; message: LocaleMessages<string> };

const createTmsMessages = async (language: Language): Promise<TMSMessage> => {
  const response = await getFrontendTranslations("DESIGN", language);
  return {
    lang: language,
    message: response.keys.additionalProperties,
  };
};

// Number formats needed so that we can format currencies.
const numberFormats = {
  en: {
    currency: {
      style: "currency",
    },
  },
  de: {
    currency: {
      style: "currency",
    },
  },
  es: {
    currency: {
      style: "currency",
    },
  },
  ru: {
    currency: {
      style: "currency",
    },
  },
  zh: {
    currency: {
      style: "currency",
    },
  },
  pt: {
    currency: {
      style: "currency",
    },
  },
  fr: {
    currency: {
      style: "currency",
    },
  },
} as const;

export const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  numberFormats: numberFormats,
});

type TranslationState = "MISSING" | "LOADING" | "AVAILABLE";

const translations: Record<Language, TranslationState> = {
  en: "MISSING",
  de: "MISSING",
  fr: "MISSING",
  es: "MISSING",
  zh: "MISSING",
  pt: "MISSING",
  ru: "MISSING",
};

async function setupTmsMessages(lang: Language): Promise<void> {
  while (true) {
    const translationStatus = translations[lang];

    if (translationStatus === "MISSING") {
      // Load language for the first time
      translations[lang] = "LOADING";
      const tmsMessage = await createTmsMessages(lang);
      i18n.global.setLocaleMessage(lang, tmsMessage.message);
      translations[lang] = "AVAILABLE";
      return;
    }

    if (translationStatus === "AVAILABLE") {
      // Language have already been set up
      return;
    }

    // There is a request for the question in process
    await delay(100);
  }
}

export type TranslateFunction = (key: string, argumentList?: string[]) => string;

export function useI18n(): { t: (key: string, argumentList?: string[]) => string };
export function useI18n(lang: Language): Promise<{ t: (key: string, argumentList?: string[]) => string }>;

export function useI18n(lang?: Language):
  | {
      t: TranslateFunction;
    }
  | Promise<{
      t: TranslateFunction;
    }> {
  if (lang !== undefined) {
    assert(Language.getValues().includes(lang), `'${lang}' is not a valid or supported ISO code (must be lower case).`);
    return setupTmsMessages(lang).then(() => {
      const { t } = i18n.global;
      return {
        t: (key: string, argumentList?: string[]) => t(key, argumentList ?? [], { locale: lang }),
      };
    });
  }
  const { t } = i18n.global;
  return {
    t: (key: string, argumentList?: string[]) => t(key, argumentList ?? []),
  };
}

export async function setI18nLanguage(lang: Language): Promise<void> {
  assert(Language.getValues().includes(lang), `'${lang}' is not a valid or supported ISO code (must be lower case).`);
  await setupTmsMessages(lang);
  i18n.global.locale.value = lang;
}
