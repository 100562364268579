import { assertIsDefined } from "@utils/assertion";
import type { ChannelAdminListView } from "@generated/model/channelAdminListView";
import type { SummaryCoverInformation } from "@generated/model/summaryCoverInformation";
import { type Dict, dictToList } from "@utils/dictUtils";
import type { SummarySourceType } from "@generated/model/summarySourceType";
import type { Country as COUNTRY } from "@generated/model/country";
import type { SummaryMetadataOld as SummaryMetadata } from "@generated/model/summaryMetadataOld";

export { DateTime } from "luxon";

export namespace Language {
  const values = ["en", "de", "es", "ru", "zh", "pt", "fr"] as const;
  export type Impl = (typeof values)[number];
  export type LanguageMap<T> = { [L in Impl]?: T };

  export function getValues(): typeof values {
    return values;
  }

  export function getLanguageId(language: Impl): number {
    return values.indexOf(language) + 1;
  }

  export function getLangByIsoCode(lang: string): Impl {
    const language = values.find((value) => value === lang.toLowerCase());
    assertIsDefined(language, `'${lang}' is not a valid language ISO code. Expected one of '${values.join(", ")}'.`);
    return language;
  }

  export function getUrlLanguage(url: string): Impl | null {
    const urlLang = values.find((value) => value === url.replace(/^\/([^\/]*).*$/, "$1").toLowerCase());
    return urlLang === undefined ? null : urlLang;
  }

  export function getCurrentLanguage(): Impl {
    const urlLang = getUrlLanguage(window.location.pathname);
    if (urlLang !== null) return urlLang;
    const sessionLang = values.find((value) => value === navigator.language.toLowerCase());
    if (sessionLang !== undefined) return sessionLang;
    return "en";
  }

  export function parseLanguageMap<T>(dict: Dict<T>, valueParser: (value: unknown) => T): LanguageMap<T> {
    return Object.assign(
      {},
      ...dictToList(dict).map((entry: [string, unknown]) => {
        const partial: Partial<Record<Impl, T>> = {};
        partial[getLangByIsoCode(entry[0])] = valueParser(entry[1]);
        return partial;
      }),
    );
  }

  export function renderLanguageMap<T, RenderedT>(map: LanguageMap<T>, valueRenderer: (value: T) => RenderedT): Dict<RenderedT> {
    const r: { [key: string]: RenderedT } = {};
    dictToList(map).forEach(([key, val]) => {
      if (val !== undefined) {
        r[key] = valueRenderer(val);
      }
    });
    return r;
  }

  export function getTmsKey(language: Impl): string {
    // keys can't be dynamically created, because of the TmsTxIdsAggregator
    switch (language) {
      case "en":
        return "general:language.en";
      case "de":
        return "general:language.de";
      case "es":
        return "general:language.es";
      case "ru":
        return "general:language.ru";
      case "zh":
        return "general:language.zh";
      case "pt":
        return "general:language.pt";
      case "fr":
        return "general:language.fr";
    }
  }
}
export type Language = Language.Impl;

export type Page<T> = {
  items: T[];
  totalCount: number | bigint;
  page: number;
  pageSize: number;
};

export type AdminToolTyp = "internal-admin" | "portal-admin";

export namespace Currency {
  const values = ["CHF", "CNY", "USD", "EUR", "GBP", "INR", "JPY"] as const;
  export type Impl = (typeof values)[number];

  export function getValues(): typeof values {
    return values;
  }
}
export type Currency = Currency.Impl;

export namespace CurrencyDeprecated {
  const values = [...Currency.getValues(), "RUB", "MILES"] as const;
  export type Impl = (typeof values)[number];

  export function getValues(): typeof values {
    return values;
  }
}
export type Country = {
  countryCode: string;
  currency: Currency;
  region: COUNTRY["region"];
  names: Record<Language, string>;
};

export type ContentItemType = "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";

export type LgxpType = "COURSE" | "SKETCHNOTE" | "LEARNINGSPRINT";

// TODO: Needs to be replaced with proper LgxpListView, kept for now to make the LGXPLIST portlet and LgxpCard work with the com.getabstract.common.dto.lgxp.LgxpListView
export type LgxpListView = {
  lgxpId: number;
  lgxpType: LgxpType;
  name: string;
  title: string;
};

export type LgxpItemType = "ABSTRACT" | "URL" | "OPEN_QUESTION";

export type ChannelIdentityListEntry = {
  channelId: number;
  name: string;
  title: string;
};

export type ChannelAdminOverviewItem = {
  channelId: number;
  name: string;
  titles: Record<string, string>;
  coverUri: string;
  children: ChannelAdminOverviewItem[];
  synonyms: string;
};

export type ChannelRelationType = "CHILD" | "RELATED" | "CONTENT_CHILD" | "STRUCTURAL_CHILD";

export type ChannelRelation = {
  channelId: number;
  otherChannel: ChannelAdminListView;
  channelRelationType: ChannelRelationType;
};

export type ContentItem = {
  contentItemId: number;
  contentItemType: ContentItemType;
  title: string | null;
  subtitle: string | null;
  teaser: string | null;
  descriptionHtml: string | null;
  imageUri: string;
  authorInfo: string | null;
  publisherInfo: string | null;
  rating: number | null;
  active: boolean;
};

type SummaryIxStatus = {
  bookmarked: boolean;
};

export type Summary = {
  name: string;
  summarySourceType: SummarySourceType | null;
  review: boolean;
  bestseller: boolean;
  ixStatus?: SummaryIxStatus;
  language: Language;
  countriesIncluded: Country[];
  countriesExcluded: Country[];
} & SummaryCoverInformation &
  ContentItem;

export type SummaryPageResponse = {
  items: SummaryMetadata[];
  page: number;
  psize: number;
  count: number;
  countTotal: number;
};

export type DropdownEntry = {
  value: string;
  label: string;
  disabled?: boolean;
};

declare const __brand: unique symbol;
type Brand<B> = { [__brand]: B };
export type Branded<T, B> = T & Brand<B>;
