import mitt from "mitt";

export const HEADER_LOCKER_LOCKED = "locker-locked";
export const HEADER_LOCKER_TYPE = "locker-type";
export const HEADER_LOCKER_ID = "locker-id";
export const HEADER_LOCKER_LEASE = "locker-lease";

export const LockerType = {
  PPS_EDITOR: "PPS_EDITOR",
  CHANNEL_ADMIN: "CHANNEL_ADMIN",
} as const;
export type LockerType = (typeof LockerType)[keyof typeof LockerType];

export type LockerKey = {
  lockerType: string;
  lockerId: string;
};

export type LockerLeaseReceivedEvent = {
  lockerKey: LockerKey;
  lockerLease: string;
};

export type LockerLockedEvent = {
  lockerKey: LockerKey;
};

type LockerEvents = {
  lockerLeaseReceivedEvent: LockerLeaseReceivedEvent;
  lockerLockedEvent: LockerLockedEvent;
};

export const lockerEventsEmitter = mitt<LockerEvents>(); //
