import { ensureNonNull } from "@utils/assertion";
import { type Translations } from "@newgenerated/shared/schema";
import { serialize_Translations, deserialize_Translations } from "@newgenerated/shared/mapper";

export { type Translations } from "@newgenerated/shared/schema";
export const parseTranslations = (data: unknown): Translations => {
  return ensureNonNull(deserialize_Translations(JSON.stringify(data)));
};
export const renderTranslations = (data: Translations): any => {
  return JSON.parse(serialize_Translations(data));
};
