import * as LosslessJSON from "lossless-json";
import { assert, assertIsDefined, ensureNonNull } from "@utils/assertion";

const numberRegex = /^[0-9]+$/;

export function parseJsonWithNumbersAsBigint(text: string): unknown {
  return LosslessJSON.parse(text, (_key, value) => {
    if (value instanceof LosslessJSON.LosslessNumber) {
      const numStr = value.toString();
      if (numberRegex.test(numStr)) {
        const num = BigInt(value.toString());
        if (num > Number.MIN_SAFE_INTEGER && num < Number.MAX_SAFE_INTEGER) {
          return Number(numStr);
        }
        return num;
      } else {
        return Number(numStr);
      }
    }
    return value;
  });
}

export function renderJsonWithNumbersAsBigint(obj: unknown, space?: string | number): string {
  assertIsDefined(obj);
  return ensureNonNull(
    LosslessJSON.stringify(obj, undefined, space, [
      {
        test: (value) => typeof value === "bigint",
        stringify: (value) => {
          assert(typeof value === "bigint");
          return value.toString();
        },
      },
    ]),
  );
}
